import { styled } from '@stitches/react'
import { COLORS } from '~/core'

export const Label = styled('span', {
  color: COLORS.TEXT_PRIMARY,
  fontSize: 16,
  fontWeight: '300',
  marginLeft: 16,
})

export const Container = styled('div',  {
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
})
