import { useEffect, useState } from 'react'
import { FeatureSwitch } from '~/components'
import {
  Title,
  Container,
  ToogleContainer,
  ToggleLabel,
  Toogle,
  Content
} from '~/styles/feature-toggle'

const TOOGLES = [
  {
    created: '07 jun 2021',
    description: 'Liga ou desliga marcações',
    domain: 'Digital Content',
    enabled: true,
    toggleKey: 'digital-content-highlight',
    name: 'Digital Content Highligt'
  },
  {
    created: '07 jun 2021',
    description: 'Liga ou desliga eureka',
    domain: 'Eureka',
    enabled: true,
    toggleKey: 'show-eureka-module',
    name: 'Eureka Module'
  },
  {
    created: '07 jun 2021',
    description: 'toggle da biometria',
    domain: 'Core',
    enabled: false,
    toggleKey: 'biometric-auth',
    name: 'Biometric Auth'
  }
]

export default function FeatureTogglePage() {
  const [toggles, setToggles] = useState({});

  useEffect(()=>{
    const _toggles = {...toggles};

    TOOGLES.forEach(toggle => {
      _toggles[toggle.toggleKey] = {...toggle};
    });

    setToggles({..._toggles});
  },[])

  const switchToggle = (toggleKey) =>{
    const _toggles = {...toggles};

    _toggles[toggleKey].enabled = !_toggles[toggleKey].enabled;

    setToggles({..._toggles});
  }

  return (
    <Container>
      <Title>Feature Toggle</Title>
      <ToogleContainer>
        <Toogle>
          <ToggleLabel>Estado</ToggleLabel>
        </Toogle>
        <Content>
          <ToggleLabel>Chave</ToggleLabel>
        </Content>
        <Content>
          <ToggleLabel>Nome</ToggleLabel>
        </Content>
        <Content>
          <ToggleLabel>Descrição</ToggleLabel>
        </Content>
        <Content>
          <ToggleLabel>Criado</ToggleLabel>
        </Content>
        <Content>
          <ToggleLabel>Domínio</ToggleLabel>
        </Content>
      </ToogleContainer>
      {Object.values(toggles).map((toggle) => (
        <FeatureSwitch
          onChange={() => {switchToggle(toggle.toggleKey)}}
          key={toggle.toggleKey}
          toggleKey={toggle.toggleKey}
          created={toggle.created}
          description={toggle.description}
          domain={toggle.domain}
          name={toggle.name}
          enabled={toggle.enabled}
        />
      ))}
    </Container>
  )
}
