import { Select, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Input, SegmentItem } from '~/components';
import { useControl } from '~/hooks/control'
import { ContainerButton, Content, OptionsContainer, Form, ParamContainer, Title, Options } from '~/styles/notifications'

// import grades from '~/data/filter/grades.json';

export default function NotificationsPage() {
  const { Option } = Select;
  const { token, currentApp, resetForm, fileBase64, getData, sendData, removeEmptyArrays, validateImageSize } = useControl();
  const [remainingCharacters, setRemainingCharacters] = useState(200);
  const [toSegment, setToSegment] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    gradeIds: [],
    profileIds: [],
    schoolIds: [],
    accountIds: [],
    lectureIds: [],
    prospection: null,
  });
  const [notification, setNotification] = useState({
    app: [], //['aluno', 'escola']
    title: null,
    message: null,
    image: null,
    imageName: null,
    deepLink: null,
    userFilter: {
      gradeIds: [],
      profileIds: [],
      schoolIds: [],
      accountIds: [],
      lectureIds: [],
      prospection: null,
    },
  });

  const updateFilterOptions = async () => {
    const _filterOptions = { ...filterOptions };
    // console.log("chamando");
    _filterOptions.gradeIds = _filterOptions.gradeIds && _filterOptions.gradeIds.length > 0 ? _filterOptions.gradeIds : await getData('notification', 'grades');
    _filterOptions.profileIds = _filterOptions.profileIds && _filterOptions.profileIds.length > 0 ? _filterOptions.profileIds : await getData('notification', 'profiles');
    _filterOptions.schoolIds = _filterOptions.schoolIds && _filterOptions.schoolIds.length > 0 ? _filterOptions.schoolIds : await getData('notification', 'schools');

    setFilterOptions(_filterOptions);
  }

  useEffect(() => {
    if (token) {
      updateFilterOptions();
    }
  }, [token]);

  const handleMessage = event => {
    const textArea = event.target;

    if (textArea) {
      setRemainingCharacters(textArea.value ? 200 - textArea.value.length : 200);
    }
  }

  const updateSegmentationOption = (list, segmentationKey) => {
    const _notification = { ...notification };

    _notification.userFilter[segmentationKey] = list ? [...list] : [];

    setNotification(_notification);
  }

  const searchInvalidFields = (event) => {
    const button = event.target;
    const { form } = button;

    Object.values(form).forEach((formElement) => {
      if (formElement && formElement.required) {
        formElement.classList.add('requiredElement');
      }
    });
  }

  const handleForm = event => {
    event.preventDefault();
    const form = event.target;
    const _notification = { ...notification };

    Object.keys(_notification).forEach(element => {
      if (form[element]) {
        _notification[element] = form[element].value;
      }

      _notification[element] = _notification[element] ? _notification[element] : null;
    });

    _notification.app = [currentApp.urlPart];

    if (validateImageSize(form.image.files)) {
      [_notification.image] = form.image.files;
      _notification.imageName = _notification.image ? _notification.image.name : null;

      fileBase64(_notification.image).then(base64 => {
        _notification.image = base64;
      }).finally(() => {
        _notification.userFilter = toSegment ? { ..._notification.userFilter } : {};

        removeEmptyArrays(_notification.userFilter);

        sendData('notification', 'message', _notification).then(response => {
          if (response === 200) {
            toast.success("Notificação enviada");
          }
          else {
            toast.warn(response);
          }
        });
      });
    }
  }

  const handleFormReset = event => {
    const cancelButton = event.target;
    const { form } = cancelButton;

    Object.values(form).forEach((formElement) => {
      // console.log(formElement.required);
      if (formElement && formElement.required) {
        formElement.classList.remove('requiredElement');
      }
    });

    resetForm(form);
    setRemainingCharacters(200);
  }
  return (
    <Content>
      <Title style={{ color: 'white' }}>{`Notificações ${currentApp.label}`}</Title>
      <ParamContainer>
        <Form onSubmit={handleForm}>
          <p>
            <Input
              id='title'
              name='title'
              type='text'
              label='Título'
              required
            />
          </p>

          <p>
            <Input
              id='message'
              name='message'
              label='Corpo'
              onInput={handleMessage}
              maxLength={200}
              altText={`Caracteres restantes: ${remainingCharacters}`}
              multiline
              required
            />
          </p>

          <p>
            <Input
              id='image'
              name='image'
              type='file'
              accept='.png, .jpg, .jpeg'
              label='Imagem'
              altText='Clique ou arraste alguma imagem para o campo acima. Tamanho máximo da imagem: 1MB'
            />
          </p>

          <p>
            <Input
              id='deeplink'
              name="deeplink"
              type="text"
              label="Deep Link"
              disabled
            />
          </p>

          <ContainerButton>
            <button onClick={searchInvalidFields} type="submit">Enviar</button>

            <button type="button" onClick={handleFormReset}>Limpar</button>
          </ContainerButton>
        </Form>

        <OptionsContainer>
          <h2>Opções</h2>
          <p>
            <Switch
              id="switchSegment"
              defaultChecked={toSegment}
              onChange={() => { setToSegment(!toSegment) }}
            />&nbsp;
            <label htmlFor="switchSegment">Segmentar notificação</label>
          </p>
          <Options className={toSegment ? 'showOptions' : undefined}>
            <SegmentItem
              id="gradeSelect"
              className="segmentSelect"
              label="Séries"
              placeholder="Selecione as séries"
              filterOptions={filterOptions.gradeIds}
              onChange={(list) => { updateSegmentationOption(list, "gradeIds") }}
            />

            <br />

            <SegmentItem
              id="profileSelect"
              className="segmentSelect"
              label="Perfis"
              placeholder="Selecione os perfis"
              filterOptions={filterOptions.profileIds}
              onChange={(list) => { updateSegmentationOption(list, "profileIds") }}
            />

            <br />

            <SegmentItem
              id="schoolSelect"
              className="segmentSelect"
              label="Escolas"
              placeholder="Selecione as escolas"
              filterOptions={filterOptions.schoolIds}
              onChange={(list) => { updateSegmentationOption(list, "schoolIds") }}
            />
          </Options>
        </OptionsContainer>
      </ParamContainer>
    </Content>
  )
}
