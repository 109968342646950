import { styled } from '@stitches/react'
import { COLORS } from '~/core'

export const Container = styled('div', {
  cursor: 'pointer',
  width: 40,
  height: 40,
  borderRadius: 20,
  backgroundColor: COLORS.TERTIARY,
  alignItems: 'center',
  justifyContent: 'center'
})
