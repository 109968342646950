// import Image from 'next/image;

import { Image } from 'antd'
import { Container } from './styles'

import imagePlaceholder from '~/data/images/sas-placeholder.png';
import imageSasAluno from '~/data/images/sas-aluno-icon.png';
import imageSasEscola from '~/data/images/sas-escola-icon.png';

/* interface AppLogoProps {
  src: string,
  size?: number,
}

interface Images{
  'sas-aluno'?: any,
  'sas-escola'?: any,
} */

const images = {
  'sas-aluno':imageSasAluno,
  'sas-escola':imageSasEscola,
}

export const AppLogo = ({ src, size = 48 }) => {  
  return (
    <Container>
      <Image
        src={src ? images[src] : imagePlaceholder}
        alt="Logo"
        width={size}
        height={size}
      />
      {/* <Image
        src={src || '/sas-placeholder.png'}
        alt="Logo"
        width={size}
        height={size}
      /> */}
    </Container>
  )
}
