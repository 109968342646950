import { COLORS } from '~/core'
import { Container, Label } from './styles'

interface NavItemProps {
  Icon: any,
  label: string,
}

export const NavItem = ({ label, Icon }: NavItemProps) => {
  return (
    <Container>
      <Icon style={{ 
          color: COLORS.TEXT_PRIMARY,
          fontSize:'1.2rem', 
        }} 
      />
      <Label>{label}</Label>
    </Container>
  )
}
