import { COLORS } from '~/core/colors'
import { styled } from '@stitches/react'
import { HEADER_HEIGHT } from '~/styles/constants'

export const Container = styled('div', {
  backgroundColor: COLORS.PRIMARY,
  flexDirection: 'row',
  alignItems: 'stretch',
  minHeight: '100vh',
  width: '100%'
})

export const Main = styled('div', {
  backgroundColor: COLORS.PRIMARY,
  flexDirection: 'column',
  width: '100%',
})

export const Content = styled('main', {
  paddingTop: HEADER_HEIGHT,
  flexDirection: 'column'
})

export const Modal = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  transition: 'all 0.3s',
  '& .background': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    background: '#222',
    opacity: '0.7',
    filter: 'blur(10px)'
  },
  variants: {
    authUser: {
      false: {
        opacity: '0',
        visibility: 'hidden'
      }
    }
  }
})
