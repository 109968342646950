import { styled } from '@stitches/react'
import { COLORS } from '~/core'

export const Container = styled('div', {
  padding: 20,
  flex: 1,
  flexDirection:'column',
})

export const Title = styled('h1', {
  color: COLORS.TEXT_PRIMARY,
  fontSize: 30,
  fontWeight: '600'
})

export const ToogleContainer = styled('div', {
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  marginTop: 45,
  borderBottom: '2px solid',
  paddingBottom: 10,
  color: COLORS.TEXT_PRIMARY,
})

export const ToggleLabel = styled('span', {
  fontWeight: '600',
  textAlign: 'center',
  '&:first-child': {
    textAlign: 'left'
  }
})

export const Content = styled('div', {
  width: 'calc((100% - 100px) / 5)',
  padding: '10px 0',
  alignItems: 'center'
})

export const Toogle = styled('div', {
  width: 100
})