import { styled } from '@stitches/react'
import { tint, transparentize } from 'polished'
import { COLORS } from '~/core'

export const AppCard = styled('span', {
  display: 'flex',
  margin: '16px 20px',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px 16px',
  backgroundColor: COLORS.TERTIARY,
  borderRadius: 16,
  transition: 'ease 0.3s',
  cursor:'pointer',
  '&:hover': {
    backgroundColor: tint(0.2, COLORS.TERTIARY),
  },
})

export const AppName = styled('h3', {
  fontSize: 16,
  color: COLORS.TEXT_PRIMARY,
  marginLeft: 16,
})

export const Menu = styled('div',{
  position:'absolute',
  width:'min(21.5%,17.8rem)',
  padding:'0 1%',
  background:transparentize(0.2, COLORS.SECONDARY),
  maxHeight:'0',
  transition:'all 0.3s',
  overflow:'hidden',
  flexDirection:'column',
  variants:{
    open:{
      true:{
        visibility:'visible',
        opacity:'1',
        padding:'1%',
        maxHeight:'12rem',
      }
    }
  }
})

export const MenuItem = styled(AppCard, {
  margin: 0,
  padding: '12px 16px',
  backgroundColor: 'transparent',
  variants: {
    selected: {
      true: {
        backgroundColor: tint(0.1, COLORS.TERTIARY),
      }
    }
  },
  '& + &': {
    marginTop: 4,
  },
})

export const MenuItemLabel = styled(AppName, {})
