import { SettingOutlined } from '@ant-design/icons'
import { CircleButton, Avatar } from '~/components'
import { Container, HeaderActions, HeaderItem } from './styles'

const USER_EXAMPLE = {
  name: 'SAS Educação user name',
  image: '/sas-placeholder.png'
}

export const Header = () => {
  return (
    <Container>
      <HeaderActions>
        <HeaderItem>
          <CircleButton
            onClick={() => {}}
            Icon={SettingOutlined}
          />
        </HeaderItem>
        <HeaderItem>
          <Avatar
            src={USER_EXAMPLE.image}
            label={USER_EXAMPLE.name}
          />
        </HeaderItem>
      </HeaderActions>
    </Container>
  )
}
