import { UserOutlined } from '@ant-design/icons';
import {Avatar as AntAvatar} from 'antd';
import { COLORS } from '~/core';
interface AvatarProps {
  src?: string,
  label: string,
}

export const Avatar = ({ src, label }: AvatarProps) => {
  const handleUserName = (name: string) => name?.split('')[0]

  return (
    <AntAvatar
      size='large'
      src={src}
      icon={!src && <UserOutlined/>}
      style={{
        background:COLORS.HIGHLIGHT,
      }}
      alt={label}
    >
      { label && handleUserName(label) }
    </AntAvatar>
  )
}
