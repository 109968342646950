import { useEffect, useState } from 'react'
import { AppLogo } from '~/components'
import {
  AppCard,
  AppName,
  Menu,
  MenuItem,
  MenuItemLabel,
} from './styles'
import { useControl } from '~/hooks/control'

const APPS = [
  {
    id: 1,
    label: 'SAS Educação Aluno',
    icon: '/sas-aluno-icon.png',
    iconName: 'sas-aluno',
    urlPart: 'aluno',
  },
  {
    id: 2,
    label: 'SAS Educação Escola',
    icon: '/sas-escola-icon.png',
    iconName: 'sas-escola',
    urlPart:'escola',
  },
]

export const AppSwitcher = () => {
  const {currentApp, setCurrentApp} = useControl();
  const [ menuAnchorState, setMenuAnchorState ] = useState<null | HTMLElement>(null)
  // const [ currentApp, setCurrentApp ] = useState<null | AppProps>(null)

  const handleClick = (event:any) => {
    const target = event.currentTarget !== menuAnchorState ? event.currentTarget : null;
    
    setMenuAnchorState(target);
  }

  const handleClose = () => {
    setMenuAnchorState(null)
  }

  const handleSelectApp = (selectedApp:any) => {
    setCurrentApp(selectedApp)
    handleClose()
  }

  useEffect(() => {
    setCurrentApp(APPS[0])
  }, [])

  return (
    <>
      <AppCard onClick={handleClick}>
        <AppLogo src={currentApp?.iconName} />
        <AppName>{ currentApp?.label }</AppName>
      </AppCard>
      <Menu 
        id="simple-menu" 
        open={menuAnchorState !== null}
      >
        {
          APPS.map((app) => (
            <MenuItem
              key={app.id}
              onClick={() => handleSelectApp(app)}
              selected={app.id === currentApp?.id}
            >
              <AppLogo src={app.iconName} />
              <MenuItemLabel>{ app.label }</MenuItemLabel>
            </MenuItem>
          ))
        }
      </Menu>
    </>
  )
}
