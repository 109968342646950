import { styled } from '@stitches/react'
import { complement, setLightness } from 'polished'

import { COLORS } from '~/core/colors'

const BUTTON = {
  CONFIRM: COLORS.HIGHLIGHT,
  CANCEL: complement(COLORS.HIGHLIGHT)
}

Object.keys(BUTTON).forEach((colorType) => {
  BUTTON[colorType] = setLightness(0.5, BUTTON[colorType])
})

export const Content = styled('section', {
  flex: 1,
  backgroundColor: COLORS.PRIMARY,
  padding: '2%',
  boxSizing: 'border-box',
  '& *': {
    boxSizing: 'border-box'
  }
})

export const ParamContainer = styled('div', {
  flexDirection: 'row'
})

export const Form = styled('form', {
  position: 'relative',
  flex: 2,
  color: 'white',
  outline: 'none',
  flexDirection: 'column',
  transition: 'all 0.3s',
  fontFamily: 'Metropolis, sans-serif',
  boxSizing: 'border-box',
  '& *': {
    fontFamily: 'Metropolis, sans-serif',
    boxSizing: 'border-box',
    transition: 'all 0.3s',
    outline: 'none'
  },
  '&>p': {
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: '0.5rem 0'
  },
  '&>p span': {
    fontSize: '0.7rem'
  },
  '& input, & textarea': {
    borderRadius: '5px'
  },
  '& textarea': {
    height: '20vh'
  }
})

export const ContainerButton = styled('div', {
  flexDirection: 'row',
  '& button': {
    padding: '0.3rem 0.3rem 0.25rem',
    cursor: 'pointer',
    background: 'none',
    border: `2px solid ${BUTTON.CONFIRM}`,
    color: BUTTON.CONFIRM,
    fontSize: '1.2rem',
    borderRadius: '10px',
    '&:last-child': {
      marginLeft: '5%',
      borderColor: BUTTON.CANCEL,
      color: BUTTON.CANCEL
    },
    '&:hover, &:focus': {
      background: BUTTON.CONFIRM,
      color: COLORS.TEXT_PRIMARY,
      '&:last-child': {
        background: BUTTON.CANCEL
      }
    }
  }
})

export const Title = styled('h1', {
  fontSize: '1.8rem',
  fontWeight: '500'
})

export const OptionsContainer = styled('section', {
  padding: '0 1%',
  flex: 1,
  width: 'auto',
  color: COLORS.TEXT_PRIMARY,
  '& h2': {
    fontSize: '1.1rem',
    fontWeight: 'bold'
  },
  '& label': {
    userSelect: 'none',
    cursor: 'pointer'
  }
})

export const Options = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  overflow:'hidden',
  maxHeight:'0',
  transition: 'all 0.5s ease-in-out',
  borderLeft:`0.5rem solid ${COLORS.HIGHLIGHT}`,
  // background: shade(0.65, COLORS.HIGHLIGHT),
  boxShadow:`2px 2px 0.2rem rgba(10,10,10,0.3)`,
  marginTop:'0.2rem',
  padding:'0 0.3rem',
  '&>span': {
    '&>.segmentSelect': {
      minWidth: '70%',
      '& .ant-select-selector': {
        borderColor: COLORS.TEXT_PRIMARY,
        color: COLORS.TEXT_PRIMARY
      },
    }
  },
  '&.showOptions':{
    maxHeight:'50vh !important',
    padding:'0.5rem 0.3rem',
  }
})
