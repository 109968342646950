import { styled } from '@stitches/react'
import { COLORS } from '~/core'

export const ToogleContainer = styled('div', {
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  marginTop: 20,
  borderBottom: '1px solid',
  paddingBottom: 10,
  color: COLORS.TEXT_SECONDARY,
  transition:'all 0.3s',
  '&.activeText':{
    color:COLORS.TEXT_PRIMARY,
  }
})

export const ToggleLabel = styled('span', {
  fontWeight: '400',
  textAlign: 'center'
})

export const Content = styled('div', {
  width: 'calc((100% - 100px) / 5)',
  padding: '10px 0',
  alignItems: 'center'
})

export const Toogle = styled('div', {
  width: 100,
  alignItems:'flex-start',
})