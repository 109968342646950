import { styled } from '@stitches/react'
import { shade, tint } from 'polished'

import { COLORS } from '~/core'
import { SIDEBAR_WIDTH } from '~/styles/constants'

export const Aside = styled('aside', {
  width: '100%',
  maxWidth: SIDEBAR_WIDTH,
  borderRight: `1px solid ${COLORS.OVERLAY}`,
  backgroundColor: COLORS.PRIMARY,
})

export const NavList = styled('div', {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
})

export const Nav = styled('nav', {})

export const MenuList = styled('ul', {})

export const MenuItem = styled('li', {})

export const MenuAnchor = styled('span', {
  padding: '16px 24px',
  display: 'block',
  borderLeft: '5px solid transparent',
  transition: 'ease 0.3s',
  cursor:'pointer',
  variants: {
    selected: {
      true: {
        borderLeft: `5px solid ${COLORS.HIGHLIGHT}`,
        backgroundColor: shade(0.7, COLORS.HIGHLIGHT),
      },
    },
  },
  '&:hover': {
    backgroundColor: tint(0.1, COLORS.PRIMARY),
  },
})
