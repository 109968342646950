import { COLORS } from '~/core'
import { Container } from './styles'

interface CircleButtonProps {
  onClick: () => void,
  Icon: any
}

export const CircleButton = ({ Icon, onClick }: CircleButtonProps) => {

  return (
    <Container onClick={onClick}>
      <Icon style={{
          color: COLORS.TEXT_PRIMARY,
          fontSize:'1.2rem', 
        }} 
      />
    </Container>
  )
}
