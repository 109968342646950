import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import { getCookie } from '~/utils/cookies';
import urls from '~/utils/urls';

export const ControlContext = createContext();

export const ControlProvider = ({ children }) => {
  const CONFIG = {
    LOCAL_STORAGE_PREFIX: "sasAdmin@",
    API: {
      NOTIFICATION: urls.NOTIFICATION_API,
      AUTH: urls.AUTH_API,
    },
    URL_LIST: {
      PORTAL_SAS: urls.PORTAL_URL,
    }
  }
  const [currentApp, setCurrentApp] = useState({});
  const [token, setToken] = useState();
  const history = useHistory();

  const onlyNumbers = evento => {
    const _campo = evento.target;

    _campo.value = _campo.value.replace(/\D/g, '');
  }

  const fileBase64 = (file) => {
    return new Promise(resolve => {
      let base64 = "";
      let reader = new FileReader();

      if (file) {
        reader.readAsDataURL(file);

        reader.onerror = error => {
          console.log(`Erro: ${error}`);
        }

        reader.onload = () => {
          base64 = reader.result;
          resolve(base64);
        }
      }
      else {
        resolve(null);
      }
    });
  }

  const errorHandler = (errorCode) => {
    switch (errorCode) {
      case 401:
        setToken(undefined);
        alert("É necessário autenticar para acessar essa página");
        window.location.href = CONFIG.URL_LIST.PORTAL_SAS
        break;

      case 404:
        toast.warn("Nenhum usuário encontrado para essa segmentação");
        break;

      case 413:
        return "Imagem muito grande. O tamanho máximo aceito é de 1MB";

      default:
        console.log("Investigue esse erro");
        return "Erro no envio";
    }
  }

  const resetForm = (form, fieldsClasses) => {
    if (window.confirm("Você tem certeza que deseja limpar o formulário?")) {
      form.reset();

      if (fieldsClasses) {
        fieldsClasses.forEach(_class => {
          const _elements = document.getElementsByClassName(_class);

          if (_elements) {
            Object.values(_elements).forEach(element => {
              element.classList.remove(_class);
            });
          }
        });
      }

      toast.warn("Formulário limpo");
    }
  }

  const getData = async (apiName, path) => {
    const { API } = CONFIG;
    apiName = apiName.toUpperCase();

    try {
      const response = await axios.get(`${API[apiName]}/${path}`, {
        headers: { 'Authorization': token },
      });

      if (response.status === 200) {
        return response.data;
      }
    }
    catch (err) {
      const { data } = Object.values(err)[2];
      if (data && data.status) {
        errorHandler(data.status);
      }
      console.warn(err);
    }
  }

  const sendData = async (apiName, path, body) => {
    const { API } = CONFIG;
    apiName = apiName.toUpperCase();

    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': token,
      };

      const response = await axios.post(`${API[apiName]}/${path}`, body, {
        headers: headers,
      });

      if (response.status === 200) {
        return 200;
      }

      return false;
    }
    catch (err) {
      const { data } = Object.values(err)[2];
      let _errorMessage = "Erro no envio"
      if (data && data.status) {
        _errorMessage = errorHandler(data.status);
      }
      console.warn(err);

      return _errorMessage;
    }
  }

  const removeEmptyArrays = rawObject => {
    Object.keys(rawObject).forEach((key) => {
      if (rawObject[key] === null || rawObject[key].length === 0) {
        delete rawObject[key];
      }
    })
  }

  const checkTokenData = async () => {
    const userData = await getData('auth', 'v1/users/me');

    if (!userData) {
      console.warn("Erro em obter userData");
      errorHandler(401);
      return;
    }

    if (!userData.profiles || userData.profiles.length === 0) {
      console.warn("Erro em obter profiles");
      errorHandler(401);
      return;
    }

    if (userData.profiles[0].id !== 1) {
      console.warn("Erro no valor do profileId");
      errorHandler(401);
      return;
    }
  }

  const validateImageSize = (imageFile) => {
    if (imageFile && imageFile[0]) {
      const validImage = imageFile[0].size && imageFile[0].size <= 1048576;

      if (!validImage) {
        errorHandler(413);
      }

      return validImage;
    }

    return true;
  }

  useEffect(() => {
    const _token = getCookie('token');

    if (_token) {
      setToken(_token);
    }
    else {
      errorHandler(401);
    }

    // setToken(getCookie('token'));
  }, []);

  useEffect(() => {
    if (token) {
      checkTokenData();
    }
  }, [token]);

  const values = {
    history,
    CONFIG,
    currentApp,
    token,
    errorHandler,
    setToken,
    setCurrentApp,
    onlyNumbers,
    resetForm,
    fileBase64,
    getData,
    sendData,
    removeEmptyArrays,
    validateImageSize,
  };

  return (
    <ControlContext.Provider value={values}>
      {children}
    </ControlContext.Provider>
  );
}


export function useControl() {
  const content = useContext(ControlContext);

  if (!content) {
    console.log("Tem que estar dentro de um Provider");
  }

  return content;
}
