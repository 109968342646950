import { styled } from "@stitches/react";
import { complement, tint } from "polished";
import { COLORS } from "~/core";

// const LABEL_MARGIN = '1.5% 1%'
/* const LABEL = {
  MARGIN: "min(1.5%,0.6rem) 1%",
  FOCUSED: {
    fontSize: "0.75rem !important",
    marginTop: "-0.7rem",
    background: COLORS.PRIMARY,
  },
}; */

export const Container = styled("span", {
  "&>.segmentSelect": {
    minWidth: "70%",
    "& .ant-select-selector": {
      borderColor: COLORS.TEXT_PRIMARY,
      color: COLORS.TEXT_PRIMARY,
    },
    "& .dropdownOverride":{
      maxWidth:'10%',
    }
  },
});
