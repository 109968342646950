import React from 'react';
import { Route } from 'react-router-dom';
import FeatureTogglePage from './feature-toggle';
import HomePage from './home';
import NotificationsPage from './notifications';

export default function Routes() {
  return (
    <>
      <Route exact path="/" component={HomePage} />

      <Route path="/home" component={HomePage} />

      <Route path="/notifications" component={NotificationsPage} />

      <Route path="/feature-toggle" component={FeatureTogglePage} />
    </>
  );
}