import { useEffect, useRef, useState } from "react";
import { AltText, Container, StyledInput, StyledLabel, StyledTextArea } from "./styles";

interface InputProps {
  id?: string,
  className?: string,
  type?: string,
  label?: string,
  altText?: string,
  name?: string,
  style?: any,
  maxLength?: number,
  defaultValue?: any,
  accept?: string,
  required?: boolean,
  disabled?: boolean,
  multiline?: boolean,
  onInput?: (event: any) => void,
  onChange?: (event: any) => void,
  onFocus?: (event: any) => void,
  onBlur?: (event: any) => void,
}

export default function Input(props: InputProps) {
  const {
    id,
    className,
    name,
    type,
    label,
    altText,
    style,
    maxLength,
    defaultValue,
    required,
    disabled,
    multiline,
    accept,
    onInput,
    onChange,
    onFocus,
    onBlur,
  } = props;
  const [filled, setFilled] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    const element:any = containerRef.current;
    if (typeof element !== 'undefined') {
      const [inputChild]:any = Object.values(element.childNodes).filter((child:any) => (child.tagName !== "LABEL" && child.tagName !== "SPAN"));

      if (inputChild && inputChild.form) {
        inputChild.form.addEventListener('reset',()=>{
          setFilled(false);
        })
      }
    }
  }, [])

  const localBlur = (event: any) => {
    const element = event.target;
    const {value,type} = element;

    if(type !== 'file' && typeof value === "string"){
      element.value = value.trim();
    }

    setFilled(element.value && element.value !== "");

    if (onBlur) {
      onBlur(event);
    }
  }

  return (
    <Container>
      {altText && (
        <AltText>{altText}</AltText>
      )}
      {multiline ? (
        <StyledTextArea
          id={id}
          name={name}
          className={className}
          style={style}
          maxLength={maxLength}
          defaultValue={defaultValue}
          required={required}
          filled={filled}
          disabled={disabled}
          onInput={onInput}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={localBlur}
        />
      ) : (
        <StyledInput
          id={id}
          name={name}
          type={type}
          className={className}
          style={style}
          maxLength={maxLength}
          defaultValue={defaultValue}
          accept={accept}
          required={required}
          filled={filled}
          disabled={disabled}
          onInput={onInput}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={localBlur}
        />
      )}
      <StyledLabel htmlFor={typeof id !== 'undefined' ? id : undefined}>{required ? `${label}*` : label}</StyledLabel>
    </Container>
  );
}