enum DARK {
  PRIMARY = '#0D0B10',
  SECONDARY = '#0A0A0D',
  TERTIARY = '#303033',
  TEXT_PRIMARY = '#FBFBFB',
  TEXT_SECONDARY = '#919EAB',
  HIGHLIGHT = '#336699',
  HIGHLIGHT_COMPLEMENTARY = '#999933',
  OVERLAY = 'rgba(255, 255, 255, 0.1)',
}

enum LIGHT {
  PRIMARY = '#0D0B10',
  SECONDARY = '#0A0A0D',
  TERTIARY = '#303033',
  TEXT_PRIMARY = '#FBFBFB',
  TEXT_SECONDARY = '#919EAB',
  HIGHLIGHT = '#336699',
  HIGHLIGHT_COMPLEMENTARY = '#999933',
  OVERLAY = 'rgba(255, 255, 255, 0.1)',
}

const defaultTheme = 'dark'

export const COLORS = defaultTheme === 'dark' ? DARK : LIGHT
// export type COLORS = typeof COLORS
