import { styled } from '@stitches/react'
import { SIDEBAR_WIDTH, HEADER_HEIGHT } from '~/styles/constants'

export const Container = styled('div', {
  position: 'fixed',
  right: 0,
  top: 0,
  left: SIDEBAR_WIDTH,
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: HEADER_HEIGHT,
  padding: '0 16px',
  backdropFilter: 'blur(6px)',
  backgroundColor: 'transparent',
})

export const HeaderItem = styled('div', {
  '& + &': {
    marginLeft: 24,
  }
})

export const HeaderActions = styled('div', {
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 'auto',
})
