// import Link from 'next/link'
// import { useRouter } from 'next/router'

import { AppSwitcher, NavItem } from '~/components'
import {
  Aside,
  Nav,
  MenuList,
  MenuItem,
  MenuAnchor,
} from './styles'
import { HomeOutlined, MessageOutlined, SwitcherOutlined } from '@ant-design/icons'
import { useControl } from '~/hooks/control'
import { Link, useHistory, useLocation } from 'react-router-dom'

type MenuItems = {
  id: number,
  Icon: object,
  label: string,
  routeName: string,
}

const MENU_ITEMS: MenuItems[] = [
  {
    id: 1,
    Icon: HomeOutlined,
    label: 'Home',
    routeName: '/'
  },
  {
    id: 2,
    Icon: SwitcherOutlined,
    label: 'Feature Toggle',
    routeName: '/feature-toggle'
  },
  {
    id: 3,
    Icon: MessageOutlined,
    label: 'Notificações',
    routeName: '/notifications'
  },
]

export const Sidebar = () => {
  const location = useLocation();

  return (
    <Aside>
      <AppSwitcher />
      <Nav>
        <MenuList>
          {
            MENU_ITEMS.map((menuItem) => (
              <Link key={menuItem.id} to={menuItem.routeName}>
                <MenuItem>
                  <MenuAnchor selected={menuItem.routeName === location?.pathname}>
                    <NavItem
                      label={menuItem.label}
                      Icon = {menuItem.Icon}
                    />
                  </MenuAnchor>
                </MenuItem>
              </Link>
            ))
          }
        </MenuList>
      </Nav>
    </Aside>
  )
}
