import { Select } from "antd";
import { Container } from "./styles";

interface SegmentProps {
  id: string,
  className?: string,
  placeholder?: string,
  label: string,
  name?: string,
  style?: any,
  filterOptions: { id: number, name: string }[],
  onChange: (event: any) => void,
}

export default function SegmentItem(props: SegmentProps) {
  const { Option } = Select;
  const {
    id,
    className,
    placeholder,
    name,
    label,
    style,
    filterOptions,
    onChange,
  } = props;

  return (
    <Container>
      <label htmlFor={id}>{`${label}: `}</label>
      <Select
        id={id}
        className={className}
        mode="multiple"
        allowClear
        placeholder={placeholder}
        onChange={onChange}
        optionLabelProp="label"
        filterOption={(input, option) => {
          if (input && option && option.label) {
            return (
              option.label.toString().toLowerCase().includes(input.toString().toLowerCase())
            )
          }

          return false;
        }}
        listItemHeight={7}
        listHeight={200}
        virtual={true}
        style={{ ...style }}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{
          maxWidth:'20%',
          whiteSpace:'nowrap',
        }}
      >
        {filterOptions && filterOptions.map(option => (
          <Option key={option.id} value={option.id} label={option.name}>
            {option.name}
          </Option>
        ))}
      </Select>
    </Container>
  );
}