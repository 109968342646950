import { styled } from '@stitches/react'
import { complement, tint } from 'polished'
import { COLORS } from '~/core'

// const LABEL_MARGIN = '1.5% 1%'
const LABEL = {
  MARGIN: 'min(1.5%,0.6rem) 1%',
  FOCUSED: {
    fontSize: '0.75rem !important',
    marginTop: '-0.7rem',
    background: COLORS.PRIMARY
  }
}

export const Container = styled('span', {
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-end',
  transition: 'all 0.3s',
  '& *': {
    transition: 'all 0.3s'
  },
  '&>label': {
    position: 'absolute',
    margin: LABEL.MARGIN,
    padding: '0 0.5%',
    fontSize: '1rem !important',
  },
  '&>input, &>textarea': {
    background: 'none',
    border: '1px solid white',
    padding: LABEL.MARGIN,
    color: 'white',
    fontSize:'0.9rem',
    resize:'none',
    '&:focus': {
      borderColor: tint(0.2, COLORS.HIGHLIGHT),
      '&~label': {
        ...LABEL.FOCUSED,
        color: tint(0.2, COLORS.HIGHLIGHT),
      },
    },
    '&[type="file"]':{
      cursor:'pointer',
      paddingTop:'2.5%',
      '&::-webkit-file-upload-button':{
        cursor:'pointer',
      },
      '&:hover':{
        borderColor:tint(0.2, COLORS.HIGHLIGHT),
        '&~label':{
          color: tint(0.2, COLORS.HIGHLIGHT),
        },
      },
      '&~label':LABEL.FOCUSED,
    },
    '&.requiredElement:invalid':{
      borderColor:complement(COLORS.HIGHLIGHT),
    },
    '&:disabled': {
      borderColor: '#999',
      '&~label': {
        color: '#999'
      }
    }
  },
  '&>input[type="file"]': {
    height: '10vh'
  }
})

export const StyledInput = styled('input', {
  variants: {
    filled: {
      true: {
        '&~label': LABEL.FOCUSED,
      }
    }
  }
})

export const StyledTextArea = styled('textarea', {
  variants: {
    filled: {
      true: {
        '&~label': LABEL.FOCUSED,
      }
    }
  }
})

export const AltText = styled('span', {
  position: 'relative',
  fontSize: '0.7rem !important',
  padding:'0.1rem 0',
})

export const StyledLabel = styled('label',{
  userSelect:'none',
})
