import { Switch } from 'antd'

interface FeatureSwitchProps {
  enabled: boolean,
  toggleKey: string,
  name: string,
  description: string,
  created: string,
  domain: string,
  onChange(): void
}

import { ToogleContainer, ToggleLabel, Content, Toogle } from './styles'

export const FeatureSwitch = ({
  created,
  description,
  domain,
  toggleKey,
  name,
  enabled,
  onChange
}: FeatureSwitchProps) => {
  return (
    <ToogleContainer className={enabled ? 'activeText' : undefined}>
      <Toogle>
        <Switch defaultChecked={enabled} onChange={onChange} />
      </Toogle>
      <Content>
        <ToggleLabel>{toggleKey}</ToggleLabel>
      </Content>
      <Content>
        <ToggleLabel>{name}</ToggleLabel>
      </Content>
      <Content>
        <ToggleLabel>{description}</ToggleLabel>
      </Content>
      <Content>
        <ToggleLabel>{created}</ToggleLabel>
      </Content>
      <Content>
        <ToggleLabel>{domain}</ToggleLabel>
      </Content>
    </ToogleContainer>
  )
}