import './App.css';
import "antd/dist/antd.dark.css";
import 'react-toastify/dist/ReactToastify.css';
import './styles/globals.css';

import { ToastContainer } from 'react-toastify';
import { ControlProvider } from './hooks/control';

import { Sidebar, Header } from '~/components';
import { Container, Main, Content } from '~/styles/global'
import Routes from './pages/routes';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3700}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />

      <ControlProvider>
        <Container>
          <Router>
            <Sidebar />
            <Main>
              <Header />
              <Content>
                <Routes />
              </Content>
            </Main>
          </Router>
        </Container>
      </ControlProvider>
    </>
  )
}

export default App