const production = {
  NOTIFICATION_API: "https://api.sasdigital.com.br/sas-app-admin-bff",
  AUTH_API: "https://auth-server.sasdigital.com.br",
  PANEL_URL: "https://app-admin.portalsaseducacao.com.br/",
  PORTAL_URL: "https://portalsaseducacao.com.br/",
}

const staging = {
  NOTIFICATION_API: "https://api.staging.sasdigital.com.br/sas-app-admin-bff",
  AUTH_API: "https://auth-server.staging.sasdigital.com.br",
  PANEL_URL: "https://app-admin.staging.portalsaseducacao.com.br/",
  PORTAL_URL: "https://portal.staging.portalsaseducacao.com.br/",
}

const development = {
  ...staging,
  PANEL_URL: "http://localhost:3000",
  PORTAL_URL: "http://localhost:8080",
}

const urlsByMode = {
  production,
  staging,
  development,
}

const urls = urlsByMode[process.env.REACT_APP_MODE]

export default urls
